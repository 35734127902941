import React, { useState, useEffect, useContext, useRef } from "react";

//Context
import { TimeContext } from "../../config/time";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import ReactEcharts from "echarts-for-react";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";
import { Typography } from "@mui/material";

export default function UptimeChart(props) {
  const [chartSettings, setChartSettings] = useState({});
  //const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const timeConfig = useContext(TimeContext).timeConfig;

  const container = useRef(null);

  const { xAxisTitle } = props;
  const { endpoint } = props;

  //const container = document.getElementById("chart");

  /*  useEffect(() => {
    if (container.current !== null && chart !== null) {
      console.log(chart);
      new ResizeObserver(() => chart.resize()).observe(container.current);
    }
  }, [container, chart]); */

  useEffect(() => {
    let isSubscribed = true;

    //if (chart !== null) chart.dispose();

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;


    setLoading(true);

    $.ajax({
      method: "GET",
      url: apiURL + endpoint + timeConfig.value,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        const thisYear = new Date(Date.now()).getFullYear();
        let values = [];
        const categories = [...new Set(res.map((item) => item.category))];
        const densities = [...new Set(res.map((item) => item.density))];
        const combination = [
          ...new Set(res.map((item) => `${item.density} ${item.category}`)),
        ];

        let minUptime = 100;
        res.forEach((item) => {
          if (item.density === "Uptime" && item.yAxis < minUptime) {
            minUptime = Math.floor(item.yAxis / 5) * 5;
          }
        })

        let months = [];

        if (timeConfig.value === "lyear") {
          months = [...new Set(res.map((item) => item.xAxis))];
        } else {
          months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
        }

        let maxHourValues = [];

        categories.forEach((cat) => {
          densities.forEach((dens) => {
            const data = [];
            months.forEach((month) => {
              data.push(
                res.find(
                  (x) =>
                    x.xAxis.trim() === month &&
                    x.category === cat &&
                    x.density === dens,
                )?.yAxis,
              );
            });

            const obj = {
              name: `${dens} ${cat}`,
              type: dens.includes("Hours") ? "bar" : "line",
              color:
                dens === "Uptime"
                  ? "#28A44D"
                  : cat === thisYear.toString()
                    ? "#C33149"
                    : "#F8B6B3",
              smooth: false,
              symbolSize: cat === thisYear.toString() ? 7 : 6,
              symbol: cat === thisYear.toString() ? "circle" : "emptyCircle",
              lineStyle: {
                borderWidth: 2,
                type: cat === (thisYear - 1).toString() ? "dotted" : "solid",
                join: "miter",
                //color: "#47995b",
              },
              itemStyle: {
                borderWidth: 0,
              },
              barWidth:
                dens.includes("Hours") && cat === (thisYear - 2).toString()
                  ? "20%"
                  : "35%",
              barGap: "0",
              data: data,
              yAxisIndex: dens === densities[0] ? 0 : 1,
            };

            if (dens.includes("Hours")) maxHourValues.push(...data);
            values.push(obj);
          });
        });

        maxHourValues = maxHourValues.filter((m) => m !== undefined);

        const maxHourValue = Math.max(...maxHourValues);

        if (isSubscribed) {
          setChartSettings({
            //color: colors,

            // Global text styles
            textStyle: {
              fontFamily: "Roboto, Arial, Verdana, sans-serif",
              fontSize: 13,
            },

            // Cha23rt animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
              left: 30,
              right: 30,
              top: 50,
              bottom: 10,
              containLabel: true,
            },

            // Add legend
            legend: {
              data: combination,
              width: 300,
              height: 200,
              itemHeight: 5,
              itemGap: 5,
              top: "top",
              //padding: 10,
            },

            // Add tooltip
            tooltip: {
              trigger: "axis",
              padding: [10, 15],
              textStyle: {
                fontSize: 13,
                fontFamily: "Roboto, sans-serif",
              },
            },

            // Horizontal axis
            xAxis: [
              {
                type: "category",
                boundaryGap: true,
                axisLabel: {
                  color: "#333",
                },
                axisLine: {
                  lineStyle: {
                    color: "#999",
                  },
                }, //Dates
                data: months,
              },
            ],

            // Vertical axis
            yAxis: [
              {
                type: "value",
                alignTicks: true,
                name: densities[0],
                nameLocation: "middle",
                nameGap:
                  maxHourValue > 10000 ? 60 : maxHourValue > 1000 ? 50 : 40,
                //nameGap: 30,
                axisLabel: {
                  formatter: "{value} ",
                  color: "#333",
                },
              },
              {
                type: "value",
                min: minUptime,
                max: 100,
                alignTicks: true,
                name: densities[1],
                nameLocation: "middle",
                nameGap: 50,
                axisLabel: {
                  formatter: (value) => {
                    if (value % 1 !== 0) {
                      return value.toFixed(2);
                    } else {
                      return value;
                    }
                  },
                  color: "#333",
                },
              },
            ],

            // Add series
            series: values,
          });
          setLoading(false);
        }
      })
      .fail(() => {
        setLoading(false);
        setError(true);
      });
    return () => (isSubscribed = false);
  }, [timeConfig, xAxisTitle, endpoint, props.id]);

  if (loading) {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"324px"}
      >
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} ref={container}>
        {error ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            height={"324px"}
          >
            <Typography color={"error"}>{"Error fetching data"}</Typography>
          </Grid>
        ) : (
          <ReactEcharts style={{ height: "324px" }} option={chartSettings} />
        )}
      </Grid>
    );
  }
}
